.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.theGallery {
  display: flex; /* Make the container a flexbox */
  flex-wrap: wrap; /* Allow the items to wrap onto multiple lines */
  justify-content: space-between; /* Distribute the items evenly along the main axis */
}

.theGallery a {
  flex: 1 0 30%; /* Allow the items to grow and shrink, but set a minimum width of 30% */
  margin: 10px; /* Add some margin around the items */
}

.theGallery img {
  width: 100%; /* Make the images fill the width of their parent element */
  height: auto; /* Preserve the aspect ratio of the images */
  object-fit: cover; /* Crop the images to fit the available space */
  max-width: 256px; /* Limit the maximum width of the images */
  max-height: 197px; /* Limit the maximum height of the images */
}
